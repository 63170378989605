import './App.css';
import {Members} from './Members';
import Controls from './Controls';
import Container from "react-bootstrap/cjs/Container";
import React from "react";
import Badge from "react-bootstrap/cjs/Badge";

function App() {
    return (
        <div className="App">
            <Container className="wrapper" fluid>
                <div className="sidebar">
                    <Controls></Controls>
                </div>
                <div className="main">
                    <div className="member-list mb-2">
                        <h1>Annuaire</h1>
                        <a href="https://www.data-infrastructure.eu/GAIAX/Navigation/EN/Home/home.html" target="_blank">
                            <div className="gaiax"></div>
                        </a>
                    </div>
                    <Members/>
                </div>
            </Container>
        </div>
    );
}

export default App;
