import React from 'react';
import {gaiaFounders, gaiaMembers} from "./data";
import ReactCountryFlag from "react-country-flag"
import Badge from "react-bootstrap/cjs/Badge";
import Card from "react-bootstrap/cjs/Card";
import ListGroup from "react-bootstrap/cjs/ListGroup";

function Member(props) {
    let classname = btoa(props.data.logo).replaceAll("=", "")
    classname += " logo "
    classname += props.data.country
    return (
        <Card className={classname}>
            <ReactCountryFlag
                className="flag-badge"
                svg
                countryCode={props.data.country}/>
            <span className="hidden">
                <a href={props.data.website} target="_blank">{props.data.name}</a>
            </span>
        </Card>

    )
}

export const Members = () => {
    const c = (o) => {
        let counter = {}
        o.forEach((f) => {
            counter[f.country] === undefined ? counter[f.country] = 1 : counter[f.country] += 1;
        })
        return counter
    }

    const hideItem = (e) => {
        const button = e.currentTarget;
        const country = button.classList[0];
        const badges = Array.from(document.getElementsByClassName("badge"))
        const items = Array.from(document.getElementsByClassName('list-item'))
        if (button.classList.contains('active-control')) {
            button.classList.remove('active-control')
            badges.forEach((b) => {
                b.classList.remove("inactive-control")
            })
            items.forEach((item) => item.classList.remove("remove"))
        } else {
            button.classList.remove('inactive-control')
            badges.forEach((b) => {
                if (b.classList.contains(country)) {
                    b.classList.add("active-control")
                } else {
                    b.classList.add("inactive-control");
                    b.classList.remove("active-control")
                }
            })
            items.forEach((item) => item.classList.contains(country) ? item.classList.remove("remove") : item.classList.add("remove"))
        }
    }

    const hideLogo = (e) => {
        const button = e.currentTarget;
        const country = button.classList[0];
        const action = (button.classList.contains('active-control')) ? "on" : "off";
        const toggleAll = Array.from(document.getElementsByClassName(action))[2]
        toggleAll.click()
        action === "off" && document.getElementById(country + "Switch").click();
        button.classList.add("active-control")
        action === "on" && button.classList.remove("active-control")
    }


    return (
        <>
            <h2>{gaiaFounders.length} membres fondateurs </h2>
            <h3 className="mb-5 member-logos">{Object.entries(c(gaiaFounders)).sort(
                (a, b) => {
                    return b[1] - a[1]
                }).map((elt, k) => {
                return <Badge
                    key={k}
                    variant="light"
                    className={elt[0] + " mr-1"}
                    onClick={hideLogo}
                >
                    {elt[1]}&nbsp;
                    <ReactCountryFlag
                        svg
                        countryCode={elt[0]}/>&nbsp;
                </Badge>
            })}</h3>
            <div className="member-logos">
                {gaiaFounders.sort(
                    (a, b) => {
                        return a.name.localeCompare(b.name)
                    }
                ).map((data, key) => {
                    return (
                        <Member data={data} key={key}></Member>
                    )
                })}
            </div>


            <h3 className="mb-5 member-list">{Object.entries(c(gaiaFounders)).sort(
                (a, b) => {
                    return b[1] - a[1]
                }).map((elt, k) => {
                return <Badge
                    key={k}
                    variant="light"
                    className={elt[0] + " mr-1"}
                    onClick={hideItem}
                >
                    {elt[1]}&nbsp;
                    <ReactCountryFlag
                        svg
                        countryCode={elt[0]}/>&nbsp;
                </Badge>
            })}</h3>
            <div className="member-list">
                <ListGroup>
                    {gaiaFounders.sort(
                        (a, b) => {
                            return a.name.localeCompare(b.name)
                        }
                    ).map((data, key) => {
                        return (
                            <ListGroup.Item className={data.country + " list-item"}><a href={data.website}
                                                                                       target="_blank">{data.name}</a>&nbsp;
                                <ReactCountryFlag countryCode={data.country} svg/> </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>

            <hr/>
            <h2 className="mt-3">{gaiaMembers.length} membres "day-1"</h2>
            <h3 className="mb-5 member-logos">{Object.entries(c(gaiaMembers)).sort((a, b) => {
                return b[1] - a[1]
            }).map((elt, k) => {
                return <Badge
                    key={k}
                    variant="light"
                    className={elt[0] + " mr-1"}
                    onClick={hideLogo}>{elt[1]} <ReactCountryFlag
                    svg
                    countryCode={elt[0]}/>&nbsp;
                </Badge>
            })}</h3>
            <div className="member-logos">
                {gaiaMembers.sort(
                    (a, b) => {
                        return a.name.localeCompare(b.name)
                    }
                ).map((data, key) => {
                    return (
                        <Member data={data} key={key}></Member>
                    )
                })}
            </div>
            <h3 className="mb-5 member-list">{Object.entries(c(gaiaMembers)).sort(
                (a, b) => {
                    return b[1] - a[1]
                }).map((elt, k) => {
                return <Badge
                    key={k}
                    variant="light"
                    className={elt[0] + " mr-1"}
                    onClick={hideItem}
                >
                    {elt[1]}&nbsp;
                    <ReactCountryFlag
                        svg
                        countryCode={elt[0]}/>&nbsp;
                </Badge>
            })}</h3>
            <div className="member-list mb-5">
                <ListGroup>
                    {gaiaMembers.sort(
                        (a, b) => {
                            return a.name.localeCompare(b.name)
                        }
                    ).map((data, key) => {
                        return (
                            <ListGroup.Item className={data.country + " list-item"}><a href={data.website}
                                                                                       target="_blank">{data.name}</a>&nbsp;
                                <ReactCountryFlag countryCode={data.country} svg/> </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
            <div className="footer">
                    <Badge>Développé par <a href="https://linkedin.com/in/zulzeen/" target="_blank">Cédric Joly</a></Badge> -
                    <Badge>Hébergé chez <a href="https://fr.outscale.com/" target="_blank">3DS Outscale</a></Badge> -
                    <Badge>Pas de cookies</Badge>
                </div>
        </>
    )
}
