import React from 'react';
import {euCountries, gaiaFounders, gaiaMembers, nonEuContries} from "./data";
import Button from "react-bootstrap/cjs/Button";
import {faEye} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import ReactCountryFlag from "react-country-flag";
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import Badge from "react-bootstrap/cjs/Badge";
import Container from "react-bootstrap/cjs/Container";

library.add(faEye)

function Controls() {
    const toggleAll = (e) => {
        const button = e.currentTarget;
        const action = button.classList.contains("on") ? "inactive-control" : "active-control";
        let target;
        if (button.classList.contains("euCountries")) {target = euCountries}
        else if (button.classList.contains("nonEuContries")) {target = nonEuContries}
        else {target = euCountries.concat(nonEuContries)}
        const all = Array.from(document.getElementsByClassName("country-control " + action));
        all.forEach((tag) => {
            if (target.indexOf(tag.id.slice(0,2)) >=0) {tag.click()}
        })

    }

    const c = (o, oo) => {
        let counter = {}
        o.forEach((f) => {
            counter[f.country] === undefined ? counter[f.country] = 1 : counter[f.country] += 1;
        })
        const res = Object.entries(counter).reduce(
            ((acc, val) => {
                return oo.indexOf(val[0]) >= 0 ? acc + val[1]: acc + 0;
            }
        ), 0)
        return res
    }

    return (
        <Container>
            <h1>Annuaire</h1>
            <a href="https://www.data-infrastructure.eu/GAIAX/Navigation/EN/Home/home.html" target="_blank"><div className="gaiax"></div></a>
            <div className="box">
            <div className="m-2">
                <h3>
                    UE<br/>
                    <Button className="off euCountries" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEyeSlash}/>
                </Button>
                <Button className="on euCountries" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEye}/>
                </Button>
                </h3>
                <Badge className="mb-2">{c(gaiaMembers, euCountries) + c(gaiaFounders, euCountries)} acteurs</Badge>
                <br/>
                {euCountries.map((c, k) => {
                    return <CountryControl country={c} key={k}/>
                })}
            </div>
            <hr/>
            <div className="m-2">
                <h3>non-UE<br/>
                <Button className="off nonEuContries" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEyeSlash}/>
                </Button>
                <Button className="on nonEuContries" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEye}/>
                </Button></h3>
                <Badge className="mb-2">{c(gaiaMembers, nonEuContries)} acteurs</Badge>
                <br/>
                {nonEuContries.map((c, k) => {
                    return <CountryControl country={c} key={k}/>
                })}
            </div>
            <hr/>
            <div className="m-2">
                <h3>
                Tous<br/>
                <Button className="off" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEyeSlash}/>
                </Button>
                <Button className="on" variant="outline-dark" onClick={toggleAll} size="sm">
                    <FontAwesomeIcon icon={faEye}/>
                </Button></h3>
            </div>
            </div>
        </Container>
    )
}

function CountryControl(props) {
    const countries = (country) => Array.from(document.getElementsByClassName(country + " card"));
    const toggleCountry = (tag) => {
        tag.classList.contains("remove") ? tag.classList.remove("remove") : tag.classList.add("remove")
    }
    const toggleCountries = (e) => {
        const button = e.currentTarget
        const badges = Array.from(document.getElementsByClassName(props.country + " badge"))
        if (button.classList.contains('active-control')) {
            badges.forEach((b) => { b.classList.remove('active-control'); b.classList.add("inactive-control")})
            button.classList.remove('active-control')
            button.classList.add('inactive-control')
        }
        else {
            badges.forEach((b) => { b.classList.remove("inactive-control")})
            console.log(button)
            button.classList.add('active-control')
            console.log(button)
            button.classList.remove('inactive-control')
            console.log(button)
        }
        countries(props.country).forEach(
            (tag) => {
                toggleCountry(tag)
            }
        );

    }
    return (
                <Button className="country-control active-control" variant="light" id={props.country + "Switch"} onClick={toggleCountries} size="lg">
                    <ReactCountryFlag svg countryCode={props.country}/>
                </Button>
    )
}

export default Controls;
export {CountryControl};