export const gaiaFounders = [
    {
      "membership type": "founding member",
      "name": "3DS OUTSCALE",
      "country": "FR",
      "logo": "3ds-outscale.png",
      "website": "https://en.outscale.com"
    },
    {
      "membership type": "founding member",
      "name": "DE-CIX",
      "country": "DE",
      "logo": "de-cix.png",
      "website": "http://www.de-cix.net"
    },
    {
      "membership type": "founding member",
      "name": "BMW Group",
      "country": "DE",
      "logo": "bmw-group.png",
      "website": "http://www.bmwgroup.com"
    },
    {
      "membership type": "founding member",
      "name": "DEUTSCHE TELEKOM",
      "country": "DE",
      "logo": "deutsche-telekom-ag.png",
      "website": "https://www.telekom.de/"
    },
    {
      "membership type": "founding member",
      "name": "ROBERT BOSCH GmbH",
      "country": "DE",
      "logo": "robert-bosch-gmbh.png",
      "website": "https://www.bosch.com/"
    },
    {
      "membership type": "founding member",
      "name": "GERMAN EDGE CLOUD GmbH & Co. KG",
      "country": "DE",
      "logo": "german-edge-cloud-gmbh-co-kg.png",
      "website": "http://www.gec.io"
    },
    {
      "membership type": "founding member",
      "name": "OVH SAS",
      "country": "FR",
      "logo": "ovh-sas.png",
      "website": "https://www.ovh.com/fr/"
    },
    {
      "membership type": "founding member",
      "name": "SIEMENS AG",
      "country": "DE",
      "logo": "siemens-ag.png",
      "website": "https://www.ovh.com/fr/"
    },
    {
      "membership type": "founding member",
      "name": "CLOUD INFRASTRUCTURE SERVICES PROVIDERS IN EUROPE (CISPE) AISBL",
      "country": "BE",
      "logo": "cloud-infrastructure-services-providers-in-europe-cispe-aisbl.png",
      "website": "http://cispe.cloud/"
    },
    {
      "membership type": "founding member",
      "name": "Scaleway",
      "country": "FR",
      "logo": "scaleway-the-cloud-that-makes-sense.png",
      "website": "https://scaleway.com"
    },
    {
      "membership type": "founding member",
      "name": "SAP SE",
      "country": "DE",
      "logo": "sap-se.png",
      "website": "http://www.sap.com"
    },
    {
      "membership type": "founding member",
      "name": "BECKHOFF AUTOMATION GmbH & Co. KG",
      "country": "DE",
      "logo": "beckhoff-automation-gmbh-co-kg.png",
      "website": "https://www.beckhoff.de/"
    },
    {
      "membership type": "founding member",
      "name": "DOCAPOSTE SAS",
      "country": "FR",
      "logo": "docaposte-sas.png",
      "website": "https://www.docaposte.eu/"
    },
    {
      "membership type": "founding member",
      "name": "ORANGE",
      "country": "FR",
      "logo": "orange.png",
      "website": "https://www.orange.com"
    },
    {
      "membership type": "founding member",
      "name": "PlusServer GmbH",
      "country": "DE",
      "logo": "plusserver-gmbh.png",
      "website": "https://www.plusserver.com/en"
    },
    {
      "membership type": "founding member",
      "name": "AMADEUS SAS",
      "country": "FR",
      "logo": "amadeus-sas.png",
      "website": "http://www.amadeus.com"
    },
    {
      "membership type": "founding member",
      "name": "ATOS SE",
      "country": "FR",
      "logo": "atos-se.png",
      "website": "https://atos.net/"
    },
    {
      "membership type": "founding member",
      "name": "SAFRAN",
      "country": "FR",
      "logo": "safran.png",
      "website": "https://www.safran-group.com/"
    },
    {
      "membership type": "founding member",
      "name": "INSTITUT MINES-T\u00c9L\u00c9COM EPSCP",
      "country": "FR",
      "logo": "institut-mines-telecom-epscp.png",
      "website": "https://www.imt.fr/"
    },
    {
      "membership type": "founding member",
      "name": "FRAUNHOFER-GESELLSCHAFT ZUR F\u00d6RDERUNG DER ANGEWANDTEN FORSCHUNG e.V.",
      "country": "DE",
      "logo": "fraunhofer-gesellschaft-zur-forderung-der-angewandten-forschung-e-v.png",
      "website": "https://www.fraunhofer.de/en.html"
    },
    {
      "membership type": "founding member",
      "name": "INTERNATIONAL DATA SPACES ASSOCIATION",
      "country": "DE",
      "logo": "international-data-spaces-association.png",
      "website": "https://www.internationaldataspaces.org/"
    },
    {
      "membership type": "founding member",
      "name": "\u00c9LECTRICIT\u00c9 DE FRANCE (EDF) SA",
      "country": "FR",
      "logo": "electricite-de-france-edf-sa.png",
      "website": "https://www.edf.fr/"
    }
]
export const gaiaMembers = [
    {
      "membership type": "day-1 member",
      "name": "The London Internet Exchange (LINX)",
      "country": "GB",
      "logo": "the-london-internet-exchange-linx.png",
      "website": "http://www.linx.net"
    },
    {
      "membership type": "day-1 member",
      "name": "1&1 IONOS SE",
      "country": "DE",
      "logo": "1-1-ionos-se.png",
      "website": "http://www.ionos.cloud"
    },
    {
      "membership type": "day-1 member",
      "name": "Open-Xchange AG",
      "country": "DE",
      "logo": "open-xchange-ag.png",
      "website": "https://www.open-xchange.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Almaviva S.p.A.",
      "country": "IT",
      "logo": "alberto-giaccone.png",
      "website": "https://www.almaviva.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Aruba S.p.A.",
      "country": "IT",
      "logo": "aruba-s-p-a.png",
      "website": "http://www.aruba.it/en"
    },
    {
      "membership type": "day-1 member",
      "name": "Schwarz IT KG (Schwarz Gruppe)",
      "country": "DE",
      "logo": "schwarz-it-kg-schwarz-gruppe.png",
      "website": "http://www.stackit.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Palantir Technologies Inc.",
      "country": "US",
      "logo": "palantir-technologies-inc.png",
      "website": "https://www.palantir.com"
    },
    {
      "membership type": "day-1 member",
      "name": "DIGITALEUROPE",
      "country": "BE",
      "logo": "digitaleurope.png",
      "website": "http://www.digitaleurope.org"
    },
    {
      "membership type": "day-1 member",
      "name": "Projixi Europe",
      "country": "FR",
      "logo": "projixi-europe.png",
      "website": "http://www.projixi-europe.com"
    },
    {
      "membership type": "day-1 member",
      "name": "ASSOSOFTWARE",
      "country": "IT",
      "logo": "assosoftware.png",
      "website": "http://www.assosoftware.it"
    },
    {
      "membership type": "day-1 member",
      "name": "MiPih (GIP - Public Interest Group)",
      "country": "FR",
      "logo": "mipih-gip-public-interest-group.png",
      "website": "http://www.mipih.fr"
    },
    {
      "membership type": "day-1 member",
      "name": "Siav S.p.A.",
      "country": "IT",
      "logo": "siav-s-p-a.png",
      "website": "https://www.siav.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Constellation",
      "country": "FR",
      "logo": "constellation.png",
      "website": "https://www.constellation.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Scheer GmbH",
      "country": "DE",
      "logo": "scheer-gmbh.png",
      "website": "https://www.scheer-group.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "OpenNebula",
      "country": "ES",
      "logo": "opennebula.png",
      "website": "https://opennebula.io"
    },
    {
      "membership type": "day-1 member",
      "name": "Volkswagen Aktiengesellschaft",
      "country": "DE",
      "logo": "volkswagen-aktiengesellschaft.png",
      "website": "https://www.volkswagenag.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Agoria",
      "country": "BE",
      "logo": "agoria.png",
      "website": "http://www.agoria.be"
    },
    {
      "membership type": "day-1 member",
      "name": "Dedagroup",
      "country": "IT",
      "logo": "dedagroup.png",
      "website": "https://www.deda.group/home"
    },
    {
      "membership type": "day-1 member",
      "name": "IRT Saint Exupery",
      "country": "FR",
      "logo": "irt-saint-exupery.png",
      "website": "http://www.irt-saintexupery.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Cloud&Heat Technologies GmbH",
      "country": "DE",
      "logo": "cloud-heat-technologies-gmbh.png",
      "website": "http://www.cloudandheat.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Royal Philips",
      "country": "NL",
      "logo": "royal-philips.png",
      "website": "https://www.philips.com/a-w/about/news/home"
    },
    {
      "membership type": "day-1 member",
      "name": "Systematic Paris-Region",
      "country": "FR",
      "logo": "systematic-paris-region.jpg",
      "website": "https://systematic-paris-region.org/fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "nicos AG",
      "country": "DE",
      "logo": "nicos-ag.png",
      "website": "https://www.nicos-ag.com"
    },
    {
      "membership type": "day-1 member",
      "name": "TIM S.p.A.",
      "country": "IT",
      "logo": "tim-s-p-a.png",
      "website": "https://www.tim.it/"
    },
    {
      "membership type": "day-1 member",
      "name": "Snowflake",
      "country": "GB",
      "logo": "snowflake.png",
      "website": "http://www.snowflake.com"
    },
    {
      "membership type": "day-1 member",
      "name": "FIWARE Foundation e.V.",
      "country": "DE",
      "logo": "fiware-foundation-e-v.png",
      "website": "https://www.fiware.org/foundation/"
    },
    {
      "membership type": "day-1 member",
      "name": "Alliance for Internet of Things Innovation AISBL (AIOTO)",
      "country": "BE",
      "logo": "alliance-for-internet-of-things-innovation-aisbl-aioto.png",
      "website": "https://aioti.eu"
    },
    {
      "membership type": "day-1 member",
      "name": "secunet",
      "country": "DE",
      "logo": "secunet.png",
      "website": "http://www.secunet.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Myra Security GmbH",
      "country": "DE",
      "logo": "myra-security-gmbh.png",
      "website": "https://www.myrasecurity.com/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "SYSTNAPS SAS",
      "country": "FR",
      "logo": "systnaps-sas.png",
      "website": "http://www.systnaps.com"
    },
    {
      "membership type": "day-1 member",
      "name": "XWiki SAS",
      "country": "FR",
      "logo": "xwiki-sas.png",
      "website": "https://xwiki.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Thales Services Numeriques",
      "country": "FR",
      "logo": "thales-services-numeriques.png",
      "website": "https://www.thalesgroup.com/fr/marches/defense-et-securite/radio-communications/communications-terrestres/customer-support-and-7"
    },
    {
      "membership type": "day-1 member",
      "name": "ownCloud GmbH",
      "country": "DE",
      "logo": "owncloud-gmbh.png",
      "website": "https://owncloud.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Hospital Cl\u00ednic de Barcelona",
      "country": "ES",
      "logo": "hospital-clinic-de-barcelona.jpeg",
      "website": "https://www.clinicbarcelona.org/"
    },
    {
      "membership type": "day-1 member",
      "name": "Fabasoft",
      "country": "AT",
      "logo": "fabasoft.png",
      "website": "http://www.fabasoft.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Sogei S.p.A.",
      "country": "IT",
      "logo": "sogei-s-p-a.png",
      "website": "https://www.sogei.it/it/sogei-homepage.html"
    },
    {
      "membership type": "day-1 member",
      "name": "Capgemini Consulting",
      "country": "FR",
      "logo": "capgemini-consulting.png",
      "website": "https://www.capgemini.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Salesforce.com, Inc.",
      "country": "US",
      "logo": "salesforce-com-inc.png",
      "website": "http://www.salesforce.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Bundesverband der Deutschen Industrie e.V.",
      "country": "DE",
      "logo": "bundesverband-der-deutschen-industrie-e-v.png",
      "website": "https://bdi.eu/"
    },
    {
      "membership type": "day-1 member",
      "name": "POSTE ITALIANE S.P.A.",
      "country": "IT",
      "logo": "poste-italiane-s-p-a.png",
      "website": "https://www.poste.it/"
    },
    {
      "membership type": "day-1 member",
      "name": "Eustema S.p.A.",
      "country": "IT",
      "logo": "eustema-s-p-a.png",
      "website": "http://www.eustema.it"
    },
    {
      "membership type": "day-1 member",
      "name": "AI4BD Deutschland GmbH",
      "country": "DE",
      "logo": "ai4bd-deutschland-gmbh.png",
      "website": "https://ai4bd.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Leonardo S.p.A.",
      "country": "IT",
      "logo": "leonardo-s-p-a.jpg",
      "website": "https://www.leonardocompany.com/en/home"
    },
    {
      "membership type": "day-1 member",
      "name": "Klarrio BV",
      "country": "BE",
      "logo": "klarrio-bv.png",
      "website": "https://klarrio.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "SAS CLEVER CLOUD",
      "country": "FR",
      "logo": "sas-clever-cloud.png",
      "website": "http://www.clever-cloud.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Netnod Internet Exchange i Sverige AB",
      "country": "SE",
      "logo": "netnod-internet-exchange-i-sverige-ab.png",
      "website": "https://www.netnod.se/"
    },
    {
      "membership type": "day-1 member",
      "name": "IRIDEOS S.p.A.",
      "country": "IT",
      "logo": "irideos-s-p-a.png",
      "website": "http://irideos.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Gigas Hosting SA",
      "country": "ES",
      "logo": "gigas-hosting-sa.png",
      "website": "https://gigas.com/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "Dawex",
      "country": "FR",
      "logo": "dawex.png",
      "website": "https://www.dawex.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Platform.sh",
      "country": "FR",
      "logo": "platform-sh.png",
      "website": "https://platform.sh"
    },
    {
      "membership type": "day-1 member",
      "name": "msg",
      "country": "DE",
      "logo": "msg.png",
      "website": "http://www.msg.group"
    },
    {
      "membership type": "day-1 member",
      "name": "Materna Information & Communications SE",
      "country": "DE",
      "logo": "materna-information-communications-se.png",
      "website": "http://www.materna.de"
    },
    {
      "membership type": "day-1 member",
      "name": "REAL COMM srl",
      "country": "IT",
      "logo": "real-comm-srl.png",
      "website": "https://realcomm.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Rapid.Space International",
      "country": "FR",
      "logo": "rapid-space-international.png",
      "website": "https://rapid.space"
    },
    {
      "membership type": "day-1 member",
      "name": "CSC - IT Center for Science Ltd.",
      "country": "FI",
      "logo": "csc-it-center-for-science-ltd.png",
      "website": "http://www.csc.fi"
    },
    {
      "membership type": "day-1 member",
      "name": "Minist\u00e8re de l'\u00e9ducation nationale, de la jeunesse et des sports",
      "country": "FR",
      "logo": "ministere-de-l-education-nationale-de-la-jeunesse-et-des-sports.jpg",
      "website": "https://www.education.gouv.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Universiteit van Amsterdam",
      "country": "NL",
      "logo": "universiteit-van-amsterdam.png",
      "website": "http://www.uva.nl"
    },
    {
      "membership type": "day-1 member",
      "name": "MAIF",
      "country": "FR",
      "logo": "maif.png",
      "website": "https://www.maif.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Amazon Europe Core S.a.r.l.",
      "country": "US",
      "logo": "amazon-europe-core-s-a-r-l.png",
      "website": "https://aws.amazon.com/fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "inno-focus businessconsulting gmbh",
      "country": "DE",
      "logo": "inno-focus-businessconsulting-gmbh.png",
      "website": "https://www.inno-focus.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Betacloud Solutions GmbH",
      "country": "DE",
      "logo": "betacloud-solutions-gmbh.png",
      "website": "https://www.betacloud.de/"
    },
    {
      "membership type": "day-1 member",
      "name": "Asociacion Innovalia",
      "country": "ES",
      "logo": "asociacion-innovalia.png",
      "website": "https://innovalia.org/"
    },
    {
      "membership type": "day-1 member",
      "name": "SNCF SA",
      "country": "FR",
      "logo": "sncf-sa.png",
      "website": "https://www.sncf.com/fr"
    },
    {
      "membership type": "day-1 member",
      "name": "Huawei Technologies Duesseldorf GmbH",
      "country": "CN",
      "logo": "huawei-technologies-duesseldorf-gmbh.png",
      "website": "https://huawei.eu/"
    },
    {
      "membership type": "day-1 member",
      "name": "Nextcloud GmbH",
      "country": "DE",
      "logo": "nextcloud-gmbh.png",
      "website": "http://nextcloud.com"
    },
    {
      "membership type": "day-1 member",
      "name": "CAISSE DES DEPOTS ET CONSIGNATIONS",
      "country": "FR",
      "logo": "caisse-des-depots-et-consignationsfrance.jpg",
      "website": "https://www.caissedesdepots.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Confindustria Digitale",
      "country": "IT",
      "logo": "confindustria-digitale.png",
      "website": "http://www.confindustriadigitale.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Vastuu Group",
      "country": "FI",
      "logo": "vastuu-group.png",
      "website": "https://www.vastuugroup.fi/fi-en"
    },
    {
      "membership type": "day-1 member",
      "name": "SysEleven GmbH",
      "country": "DE",
      "logo": "syseleven-gmbh.png",
      "website": "https://www.syseleven.de/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "European Committee for Interoperable Systems (ECIS)",
      "country": "BE",
      "logo": "european-committee-for-interoperable-systems-ecis.jpg",
      "website": "http://www.ecis.eu"
    },
    {
      "membership type": "day-1 member",
      "name": "Europacable AISBL",
      "country": "BE",
      "logo": "europacable-aisbl.png",
      "website": "https://www.europacable.eu/"
    },
    {
      "membership type": "day-1 member",
      "name": "VTT Technical Research Centre of Finland",
      "country": "FI",
      "logo": "vtt-technical-research-centre-of-finland.png",
      "website": "https://www.vttresearch.com/en"
    },
    {
      "membership type": "day-1 member",
      "name": "Bitkom e.V.",
      "country": "DE",
      "logo": "bitkom-e-v.png",
      "website": "https://www.bitkom.org/"
    },
    {
      "membership type": "day-1 member",
      "name": "WESTPOLE S.p.A.",
      "country": "IT",
      "logo": "westpole-s-p-a.png",
      "website": "https://www.westpole.it/it/"
    },
    {
      "membership type": "day-1 member",
      "name": "TAS STEVEN",
      "country": "BE",
      "logo": "tas-steven.png",
      "website": "https://www.proximus.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Manufacture Francaise des Pneus Michelin",
      "country": "FR",
      "logo": "manufacture-francaise-des-pneus-michelin.jpg",
      "website": "http://michelin.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Deepshore GmbH",
      "country": "DE",
      "logo": "deepshore-gmbh.png",
      "website": "https://deepshore.de/"
    },
    {
      "membership type": "day-1 member",
      "name": "Cefriel s.cons.r.l.",
      "country": "IT",
      "logo": "cefriel-s-cons-r-l.png",
      "website": "http://www.cefriel.com"
    },
    {
      "membership type": "day-1 member",
      "name": "SystemX",
      "country": "FR",
      "logo": "systemx.png",
      "website": "https://www.irt-systemx.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "AIRBUS SAS",
      "country": "FR",
      "logo": "airbus-sas.png",
      "website": "http://www.airbus.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Seagate Systems Ireland Ltd.",
      "country": "US",
      "logo": "seagate-systems-ireland-ltd.jpg",
      "website": "https://www.seagate.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Reply",
      "country": "IT",
      "logo": "reply.png",
      "website": "http://www.reply.com"
    },
    {
      "membership type": "day-1 member",
      "name": "LINAGORA",
      "country": "FR",
      "logo": "linagora.png",
      "website": "http://www.linagora.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Google Cloud",
      "country": "US",
      "logo": "google-cloud.png",
      "website": "http://cloud.google.com"
    },
    {
      "membership type": "day-1 member",
      "name": "IBM Belgium bvba/sprl",
      "country": "US",
      "logo": "ibm-belgium-bvba-sprl.png",
      "website": "https://www.ibm.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Mouvement des entreprises de France (MEDEF)",
      "country": "FR",
      "logo": "mouvement-des-entreprises-de-france-medef.jpg",
      "website": "https://medef.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "ProvenRun",
      "country": "FR",
      "logo": "provenrun.png",
      "website": "http://www.provenrun.com"
    },
    {
      "membership type": "day-1 member",
      "name": "SupplyOn AG",
      "country": "DE",
      "logo": "supplyon-ag.png",
      "website": "http://www.supplyon.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Var Group",
      "country": "IT",
      "logo": "var-group.png",
      "website": "http://www.vargroup.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Deutsche Bank AG",
      "country": "DE",
      "logo": "deutsche-bank-ag.png",
      "website": "https://www.db.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Selbstregulierung Informationswirtschaft e.V. (SRIW)",
      "country": "DE",
      "logo": "selbstregulierung-informationswirtschaft-e-v-sriw.png",
      "website": "https://sriw.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Oracle Corporation",
      "country": "US",
      "logo": "oracle-corporation.png",
      "website": "https://www.oracle.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Intesa Sanpaolo",
      "country": "IT",
      "logo": "intesa-sanpaolo.png",
      "website": "https://www.intesasanpaolo.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "DENIC eG",
      "country": "DE",
      "logo": "denic-eg.png",
      "website": "https://www.denic.de/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "TOP-IX",
      "country": "IT",
      "logo": "top-ix.png",
      "website": "http://www.top-ix.org"
    },
    {
      "membership type": "day-1 member",
      "name": "qbee AS",
      "country": "NO",
      "logo": "qbee-as.png",
      "website": "https://qbee.io"
    },
    {
      "membership type": "day-1 member",
      "name": "ZVEI - Zentralverband Elektrotechnik- und Elektronikindustrie e.V.",
      "country": "DE",
      "logo": "zvei-zentralverband-elektrotechnik-und-elektronikindustrie-e-v.png",
      "website": "http://www.zvei.org"
    },
    {
      "membership type": "day-1 member",
      "name": "Ericsson",
      "country": "SE",
      "logo": "ericsson.png",
      "website": "http://www.ericsson.com"
    },
    {
      "membership type": "day-1 member",
      "name": "senseering GmbH",
      "country": "DE",
      "logo": "senseering-gmbh.png",
      "website": "http://www.senseering.de"
    },
    {
      "membership type": "day-1 member",
      "name": "4Com GmbH & Co. KG",
      "country": "DE",
      "logo": "4com-gmbh-co-kg.png",
      "website": "http://www.4Com.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Credit Agricole SA",
      "country": "FR",
      "logo": "credit-agricole-sa.png",
      "website": "https://www.credit-agricole.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Hosteur",
      "country": "FR",
      "logo": "hosteur.png",
      "website": "https://www.hosteur.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "BOTLabs GmbH",
      "country": "DE",
      "logo": "botlabs-gmbh.png",
      "website": "https://www.kilt.io/"
    },
    {
      "membership type": "day-1 member",
      "name": "highQ Computerl\u00f6sungen GmbH",
      "country": "DE",
      "logo": "highq-computerlosungen-gmbh.png",
      "website": "https://www.highq.de/"
    },
    {
      "membership type": "day-1 member",
      "name": "Retelit S.p.a.",
      "country": "IT",
      "logo": "retelit-s-p-a.png",
      "website": "https://www.retelit.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Bit4id Srl",
      "country": "IT",
      "logo": "bit4id-srl.png",
      "website": "https://bit4id.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "B1 Systems GmbH",
      "country": "DE",
      "logo": "b1-systems-gmbh.png",
      "website": "http://www.b1-systems.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Engie SA",
      "country": "FR",
      "logo": "engie-sa.png",
      "website": "https://www.engie.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "CCEX Cloud Commodities Exchange GmbH",
      "country": "DE",
      "logo": "ccex-cloud-commodities-exchange-gmbh.png",
      "website": "http://www.ccexgroup.com"
    },
    {
      "membership type": "day-1 member",
      "name": "BigchainDB GmbH",
      "country": "DE",
      "logo": "bigchaindb-gmbh.png",
      "website": "https://oceanprotocol.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "EuroCloud Deutschland_eco e.V.",
      "country": "DE",
      "logo": "eurocloud-deutschland-eco-e-v.png",
      "website": "http://www.eurocloud.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Fujitsu",
      "country": "JP",
      "logo": "fujitsu.png",
      "website": "https://fujitsu.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Confindustria Servizi Innovativi e Tecnologici",
      "country": "IT",
      "logo": "confindustria-servizi-innovativi-e-tecnologici.png",
      "website": "http://www.confindustriasi.it"
    },
    {
      "membership type": "day-1 member",
      "name": "BPCE SA",
      "country": "FR",
      "logo": "bpce-sa.jpg",
      "website": "https://groupebpce.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Microsoft NV",
      "country": "US",
      "logo": "microsoft-nv.png",
      "website": "https://www.microsoft.com"
    },
    {
      "membership type": "day-1 member",
      "name": "TNO",
      "country": "NL",
      "logo": "tno.png",
      "website": "http://www.tno.nl/"
    },
    {
      "membership type": "day-1 member",
      "name": "Rhea System S.A.",
      "country": "BE",
      "logo": "rhea-system-s-a.png",
      "website": "https://www.rheagroup.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Telekom Austria AG",
      "country": "AT",
      "logo": "telekom-austria-ag.png",
      "website": "https://www.a1.group/"
    },
    {
      "membership type": "day-1 member",
      "name": "BNP Paribas",
      "country": "FR",
      "logo": "bnp-paribas.png",
      "website": "http://www.bnpparibas.com"
    },
    {
      "membership type": "day-1 member",
      "name": "EGI Federation",
      "country": "NL",
      "logo": "egi-federation.png",
      "website": "https://www.egi.eu/"
    },
    {
      "membership type": "day-1 member",
      "name": "OpenUK",
      "country": "GB",
      "logo": "openuk.png",
      "website": "http://www.openuk.uk"
    },
    {
      "membership type": "day-1 member",
      "name": "Threefold Tech",
      "country": "BE",
      "logo": "threefold-tech.png",
      "website": "https://www.threefold.io"
    },
    {
      "membership type": "day-1 member",
      "name": "Cisco Systems Belgium BV",
      "country": "US",
      "logo": "cisco-systems-belgium-bv.png",
      "website": "https://www.cisco.com"
    },
    {
      "membership type": "day-1 member",
      "name": "ETNO AISBL",
      "country": "BE",
      "logo": "etno-aisbl.png",
      "website": "http://www.etno.eu"
    },
    {
      "membership type": "day-1 member",
      "name": "VMWARE International Unlimited Company",
      "country": "US",
      "logo": "vmware-international-unlimited-company.png",
      "website": "https://www.vmware.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Enel Global Services S.r.l.",
      "country": "IT",
      "logo": "enel-global-services-s-r-l.png",
      "website": "https://www.enel.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "Hewlett Packard Enterprise",
      "country": "US",
      "logo": "hewlett-packard-enterprise.png",
      "website": "http://www.hpe.com/de"
    },
    {
      "membership type": "day-1 member",
      "name": "Alibaba Cloud Intelligence",
      "country": "CN",
      "logo": "alibaba-cloud-intelligence.png",
      "website": "https://eu.alibabacloud.com/en"
    },
    {
      "membership type": "day-1 member",
      "name": "EBRC S.A.",
      "country": "LU",
      "logo": "ebrc-s-a.jpg",
      "website": "http://www.ebrc.com"
    },
    {
      "membership type": "day-1 member",
      "name": "DATEV eG",
      "country": "DE",
      "logo": "datev-eg.png",
      "website": "https://www.datev.de/web/de/startseite/startseite-n/"
    },
    {
      "membership type": "day-1 member",
      "name": "VDMA e.V.",
      "country": "DE",
      "logo": "vdma-e-v.jpeg",
      "website": "https://www.vdma.org/"
    },
    {
      "membership type": "day-1 member",
      "name": "ICT Austria, Center for Business TechnologyVerein f\u00fcr \u00d6sterreichische IKT Unternehmen",
      "country": "AT",
      "logo": "ict-austria-center-for-business-technologyverein-fur-osterreichische-ikt-unternehmen.png",
      "website": "https://www.ictaustria.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "APIIDA AG",
      "country": "DE",
      "logo": "apiida-ag.png",
      "website": "https://apiida.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Accenture Global Solutions Ltd.",
      "country": "US",
      "logo": "accenture-global-solutions-ltd.png",
      "website": "https://www.accenture.com/"
    },
    {
      "membership type": "day-1 member",
      "name": "DRACOON GmbH",
      "country": "DE",
      "logo": "dracoon-gmbh.png",
      "website": "http://www.dracoon.com"
    },
    {
      "membership type": "day-1 member",
      "name": "fortiss GmbH",
      "country": "DE",
      "logo": "fortiss-gmbh.png",
      "website": "https://www.fortiss.org/"
    },
    {
      "membership type": "day-1 member",
      "name": "eco - Association of he Internet Industry",
      "country": "DE",
      "logo": "eco-association-of-he-internet-industry.png",
      "website": "http://www.eco.de"
    },
    {
      "membership type": "day-1 member",
      "name": "Adobis Group",
      "country": "FR",
      "logo": "adobis-group.png",
      "website": "http://adobisgroup.fr"
    },
    {
      "membership type": "day-1 member",
      "name": "Intel Corporation",
      "country": "US",
      "logo": "intel-corporation.png",
      "website": "http://www.intel.com"
    },
    {
      "membership type": "day-1 member",
      "name": "ODN GmbH & Co.KG",
      "country": "DE",
      "logo": "odn-gmbh-co-kg.png",
      "website": "https://www.odn.de/"
    },
    {
      "membership type": "day-1 member",
      "name": "Eurolink",
      "country": "IT",
      "logo": "eurolink.png",
      "website": "http://www.eurolink.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Omnis Cloud S.a.r.l.",
      "country": "LU",
      "logo": "omnis-cloud-s-a-r-l.png",
      "website": "https://www.omniscloud.eu"
    },
    {
      "membership type": "day-1 member",
      "name": "ExaMesh GmbH",
      "country": "DE",
      "logo": "examesh-gmbh.png",
      "website": "https://examesh.de/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "FASTNET S.p.A.",
      "country": "IT",
      "logo": "fastnet-s-p-a.png",
      "website": "http://www.fastnet.it/"
    },
    {
      "membership type": "day-1 member",
      "name": "AGDATAHUB SAS",
      "country": "FR",
      "logo": "agdatahub-sas.png",
      "website": "https://www.agdatahub.eu/en"
    },
    {
      "membership type": "day-1 member",
      "name": "Kompetenznetzwerk Trusted Cloud e.V.",
      "country": "DE",
      "logo": "kompetenznetzwerk-trusted-cloud-e-v.png",
      "website": "https://www.trusted-cloud.de/en/about-trusted-cloud"
    },
    {
      "membership type": "day-1 member",
      "name": "Insentis GmbH",
      "country": "DE",
      "logo": "insentis-gmbh.png",
      "website": "http://www.insentis.com"
    },
    {
      "membership type": "day-1 member",
      "name": "Engineering Ingegneria Informatica",
      "country": "IT",
      "logo": "engineering-ingegneria-informatica.png",
      "website": "http://www.eng.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Ecole Normale Superieure Paris-Saclay (ENS Paris-Saclay)",
      "country": "FR",
      "logo": "ecole-normale-superieure-paris-saclay-ens-paris-saclay.png",
      "website": "https://ens-paris-saclay.fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Wemedoo AG",
      "country": "CH",
      "logo": "wemedoo-ag.png",
      "website": "http://www.wemedoo.com"
    },
    {
      "membership type": "day-1 member",
      "name": "CS GROUP",
      "country": "FR",
      "logo": "cs-group.png",
      "website": "http://csgroup.eu"
    },
    {
      "membership type": "day-1 member",
      "name": "LinUp Srl",
      "country": "IT",
      "logo": "linup-srl.png",
      "website": "http://www.linup.it"
    },
    {
      "membership type": "day-1 member",
      "name": "Netalia S.r.l.",
      "country": "IT",
      "logo": "netalia-s-r-l.png",
      "website": "http://www.netalia.it"
    },
    {
      "membership type": "day-1 member",
      "name": "ITI - Instituto Tecnol\u00f3gico de Inform\u00e1tica",
      "country": "ES",
      "logo": "iti-instituto-tecnologico-de-informatica.png",
      "website": "https://www.iti.es/"
    },
    {
      "membership type": "day-1 member",
      "name": "IP Telecom, Servi\u00e7os de Telecomunica\u00e7\u00f5es, S.A.",
      "country": "PT",
      "logo": "ip-telecom-servicos-de-telecomunicacoes-s-a.png",
      "website": "https://www.iptelecom.pt"
    },
    {
      "membership type": "day-1 member",
      "name": "Blade",
      "country": "FR",
      "logo": "blade.png",
      "website": "https://shadow.tech/fr/"
    },
    {
      "membership type": "day-1 member",
      "name": "Cy4Gate",
      "country": "IT",
      "logo": "cy4gate.png",
      "website": "https://www.cy4gate.com/en/"
    },
    {
      "membership type": "day-1 member",
      "name": "Haier COSMO IoT Ecosystem Techology Co., Ltd.",
      "country": "CN",
      "logo": "haier-cosmo-iot-ecosystem-techology-co-ltd.png",
      "website": "https://www.haier.com/global/haier_cosmoplat/"
    },
    {
      "membership type": "day-1 member",
      "name": "Thinkport GmbH",
      "country": "DE",
      "logo": "thinkport-gmbh.png",
      "website": "http://thinkport.digital/"
    }
]


export const euCountries = ['AT', 'BE', 'DE', 'ES', 'FI', 'FR', 'IT', 'LU', 'NL', 'PT', 'SE']
export const nonEuContries = ['CH', 'CN', 'GB','NO','JP', 'US']